<template>
  <div class="calculator">
    <div class="newton-calculator">
      <div class="header-with-info">
        <h3>Berechnung von Flächen und Volumen</h3>
        <div class="info-icon" @mouseenter="showInfo = true" @mouseleave="showInfo = false">
          <span>i</span>
          <div class="info-popup" v-if="showInfo">
            <info />
          </div>
        </div>
      </div>
      <div class="calculation-type">
        <label>Berechnen:</label>
        <select v-model="calculationType">
          <option value="A2">A2 (untere Fläche)</option>
          <option value="A1">A1 (obere Fläche)</option>
          <option value="V">V (Volumen)</option>
        </select>
      </div>

      <div class="newton-inputs">
        <div class="input-group" v-if="calculationType !== 'A1'">
          <label>A1 (obere Fläche in m²):</label>
          <input 
            type="number" 
            v-model="newtonInputs.A1" 
            step="0.0001"
            :disabled="calculationType === 'A1'"
          >
        </div>
        
        <div class="input-group" v-if="calculationType !== 'A2'">
          <label>A2 (untere Fläche in m²):</label>
          <input 
            type="number" 
            v-model="newtonInputs.A2" 
            step="0.0001"
            :disabled="calculationType === 'A2'"
          >
        </div>
        
        <div class="input-group" v-if="calculationType !== 'V'">
          <label>V (Volumen in m³):</label>
          <input 
            type="number" 
            v-model="newtonInputs.V" 
            step="0.0001"
            :disabled="calculationType === 'V'"
          >
        </div>
        
        <div class="input-group">
          <label>h (Höhe in m):</label>
          <input 
            type="number" 
            v-model="newtonInputs.h" 
            step="0.01"
          >
        </div>
      </div>

      <button @click="calculate" class="calculate-btn">
        Berechnen
      </button>

      <div v-if="newtonResult" class="newton-result">
        <h4>Ergebnis:</h4>
        <p v-if="calculationType === 'A2'">
          A2 (untere Fläche) = {{ newtonResult.toFixed(6) }} m²
        </p>
        <p v-if="calculationType === 'A1'">
          A1 (obere Fläche) = {{ newtonResult.toFixed(6) }} m²
        </p>
        <p v-if="calculationType === 'V'">
          V (Volumen) = {{ newtonResult.toFixed(6) }} m³
        </p>
      </div>

      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <div v-if="newtonResult" class="visualization-section">
        <h4>Vergleichswerte:</h4>
        
        <div class="comparison-cards">
          <!-- Area Comparisons -->
          <div v-if="calculationType !== 'V'" class="comparison-card football-field-card">
            <h5>Fußballfeld-Vergleich</h5>
            <p>{{ (newtonResult / 7140 * 100).toFixed(4) }}% eines Fußballfelds</p>
            <div class="football-field">
              <div class="field-outline">
                <!-- Field markings -->
                <div class="center-circle"></div>
                <div class="center-line"></div>
                <div class="penalty-area left"></div>
                <div class="penalty-area right"></div>
                <div class="goal left"></div>
                <div class="goal right"></div>
                
                <!-- Calculated area overlay -->
                <div 
                  class="calculated-area"
                  :style="{
                    width: `${Math.min((newtonResult / 7140) * 100, 100)}%`,
                    opacity: 0.5
                  }"
                ></div>
              </div>
              <span class="detail">(Standardgröße: 7.140 m²)</span>
            </div>
          </div>

          <!-- Volume Comparisons -->
          <div v-if="calculationType === 'V'" class="comparison-card">
            <img src="@/assets/truck.svg" alt="LKW">
            <div class="comparison-info">
              <h5>LKW-Ladungen (40t)</h5>
              <p>{{ (newtonResult / 25).toFixed(2) }} LKW-Ladungen</p>
              <span class="detail">(ca. 25m³ pro Ladung)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from './info.vue'

export default {
  name: 'ExCalc',
  components: {
    Info
  },
  data() {
    return {
      calculationType: 'A2',
      newtonInputs: {
        A1: 0.002,
        A2: 0.001,
        V: 0.00007357,
        h: 0.05
      },
      newtonResult: null,
      errorMessage: null,
      showInfo: false
    }
  },

  methods: {
    solveA2(A1, V, h, tol = 1e-6, maxIter = 100) {
      if (A1 <= 0 || V <= 0 || h <= 0) {
        throw new Error("A1, V, und h müssen positive Werte sein.");
      }

      const target = (3 * V) / h - A1;

      if (target <= 0) {
        throw new Error(
          `Ungültige Eingabewerte: (3 * V) / h - A1 muss positiv sein.\n` +
          `Berechnung: (3 * ${V}) / ${h} - ${A1} = ${target}`
        );
      }

      let A2 = A1 / 2;

      for (let i = 0; i < maxIter; i++) {
        if (A2 <= 0) {
          throw new Error(
            `Iterationsfehler: A2 ist negativ geworden in Iteration ${i}. ` +
            `Prüfe die Eingabewerte.`
          );
        }

        const f = A2 + Math.sqrt(A1 * A2) - target;
        const df = 1 + 0.5 * Math.sqrt(A1 / A2);
        const A2_new = A2 - f / df;

        if (Math.abs(A2_new - A2) < tol) {
          return A2_new;
        }

        A2 = A2_new;
      }

      throw new Error("Newton's method did not converge within the maximum number of iterations.");
    },

    solveA1(A2, V, h, tol = 1e-6, maxIter = 100) {
      if (A2 <= 0 || V <= 0 || h <= 0) {
        throw new Error("A2, V, und h müssen positive Werte sein.");
      }

      let A1 = A2 * 2;
      
      for (let i = 0; i < maxIter; i++) {
        const f = A1 + Math.sqrt(A1 * A2) + A2 - (3 * V) / h;
        const df = 1 + 0.5 * Math.sqrt(A2 / A1);
        const A1_new = A1 - f / df;
        
        if (Math.abs(A1_new - A1) < tol) {
          return A1_new;
        }
        
        A1 = A1_new;
      }
      
      throw new Error("Newton's method did not converge for A1 calculation.");
    },

    calculateV(A1, A2, h) {
      if (A1 <= 0 || A2 <= 0 || h <= 0) {
        throw new Error("A1, A2, und h müssen positive Werte sein.");
      }
      
      return (h * (A1 + Math.sqrt(A1 * A2) + A2)) / 3;
    },

    calculate() {
      this.errorMessage = null;
      this.newtonResult = null;
      
      try {
        const { A1, A2, V, h } = this.newtonInputs;
        
        switch(this.calculationType) {
          case 'A2':
            this.newtonResult = this.solveA2(Number(A1), Number(V), Number(h));
            break;
          case 'A1':
            this.newtonResult = this.solveA1(Number(A2), Number(V), Number(h));
            break;
          case 'V':
            this.newtonResult = this.calculateV(Number(A1), Number(A2), Number(h));
            break;
        }
      } catch (error) {
        this.errorMessage = error.message;
      }
    }
  }
}
</script>

<style scoped>
.calculator {
  max-width: 600px;
  margin: 0 auto;
}

.newton-calculator {
  background: white;
  padding: 5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.calculation-type {
  margin-bottom: 1rem;
  display: flex;
  gap: 3rem;
  align-items: center;
}

.calculation-type select {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: white;
}

.newton-inputs {
  display: grid;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  color: #4a5568;
  font-size: 0.9rem;
}

input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  width: 100%;
}

input:focus {
  outline: none;
  border-color: #42b983;
  box-shadow: 0 0 0 3px rgba(66, 185, 131, 0.1);
}

.calculate-btn {
  background: #42b983;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1rem;
  width: 100%;
}

.calculate-btn:hover {
  background: #3aa876;
  transform: translateY(-1px);
}

.newton-result {
  margin-top: 1rem;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 8px;
}

.newton-result h4 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.newton-result p {
  color: #42b983;
  font-size: 1.1rem;
  font-weight: 600;
}

.error-message {
  margin-top: 1rem;
  padding: 1rem;
  background: #fee2e2;
  color: #dc2626;
  border-radius: 8px;
  font-size: 0.9rem;
}

.visualization-section {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.comparison-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 1rem;
}

/* Base comparison card styles */
.comparison-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--paper-color);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(44, 62, 80, 0.1);
  transition: transform 0.2s;
  text-align: center;
}

/* Football field specific styles */
.football-field-card {
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.football-field {
  position: relative;
  width: 100%;
  margin-top: 1rem;
}

.field-outline {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background: #42b983;
  border: 1px solid white;
  border-radius: 2px;
  overflow: hidden;
}

.center-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  border: 1px solid white;
  border-radius: 50%;
}

.center-line {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background: white;
}

.penalty-area {
  position: absolute;
  top: 50%;
  width: 20%;
  height: 60%;
  border: 1px solid white;
  transform: translateY(-50%);
}

.penalty-area.left {
  left: 0;
}

.penalty-area.right {
  right: 0;
}

.goal {
  position: absolute;
  top: 50%;
  width: 5%;
  height: 30%;
  border: 1px solid white;
  transform: translateY(-50%);
}

.goal.left {
  left: 0;
}

.goal.right {
  right: 0;
}

.calculated-area {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(33, 150, 243, 0.7);
  transition: width 0.5s ease-out;
}

.comparison-card h5 {
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
}

.comparison-card p {
  font-size: 1.4rem;
  margin-bottom: 0.75rem;
}

.detail {
  display: block;
  margin-top: 0.5rem;
  text-align: center;
}

.comparison-info {
  margin-top: 0.75rem;
}

.header-with-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.info-icon {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--primary-color);
}

.info-popup {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 300px;
  background: var(--paper-color);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(44, 62, 80, 0.2);
  z-index: 1000;
}

/* Add mobile styles */
@media (max-width: 480px) {
  .newton-calculator {
    padding: 1.5rem;
    margin: 0.5rem;
  }

  .visualization-section {
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .comparison-card {
    padding: 0;
    box-shadow: none;
    background: none;
  }

  .football-field {
    margin-top: 0.5rem;
  }

  .calculation-type {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .info-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 320px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .header-with-info {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .info-icon {
    margin-left: auto;
  }

  .calculate-btn {
    margin-top: 1rem;
  }

  .newton-inputs {
    gap: 1rem;
  }

  input, select {
    padding: 0.5rem;
    font-size: 1rem;
  }

  .comparison-card {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: calc(100% + 3rem);
  }
}

/* For even smaller screens */
@media (max-width: 320px) {
  .newton-calculator {
    padding: 1rem;
  }

  .visualization-section {
    padding: 1rem;
  }

  .football-field-card {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }
}
</style>
