# Create a new view for ISYBAU XML functionality
<template>
  <div class="isybau-container">
    <div class="header">
      <h1>ISYBAU XML</h1>
      <p class="subtitle">Isybauxml - Daten zu Massen übersicht</p>
    </div>

    <!-- Upload/Download Controls -->
    <UpDownButton 
      @file-selected="handleFileUpload"
      @download-requested="handleDownload"
    />

    <!-- Status Messages -->
    <div v-if="statusMessage" :class="['status-message', statusType]">
      {{ statusMessage }}
    </div>

    <!-- XML Preview - Always visible after upload -->
    <div v-if="xmlContent" class="xml-preview-section">
      <h3>XML Inhalt</h3>
      <XmlFix
        :xmlContent="xmlContent"
        :errorDetails="xmlError"
        :readOnly="!hasError"
        @save="handleXmlSave"
        @cancel="clearError"
      />
    </div>

    <!-- Generate Excel Button - shows only after successful upload -->
    <div v-if="showGenerateButton" class="generate-section">
      <button 
        class="generate-btn"
        @click="handleGenerateExcel"
        :disabled="isGenerating || hasError"
      >
        <span class="icon">📊</span>
        {{ isGenerating ? 'Generiere Excel...' : 'Generiere Excel' }}
      </button>
    </div>

    <!-- Excel Preview Section -->
    <div v-if="generatedFiles.length" class="excel-preview-section">
      <h3>Generierte Dateien</h3>
      <div class="file-list">
        <div v-for="file in generatedFiles" 
             :key="file.filename" 
             class="file-item">
          <div class="file-info">
            <span class="file-type">
              {{ getFileTypeLabel(file.type) }}
            </span>
            <span class="file-name">{{ file.filename }}</span>
            <span v-if="file.description" class="file-description">
              {{ file.description }}
            </span>
          </div>
          <div class="file-actions">
            <button v-if="file.type.includes('csv')" 
                    @click="previewFile(file)" 
                    class="preview-btn">
              👁️ Vorschau
            </button>
            <button @click="downloadFile(file.filename)" 
                    class="download-btn">
              ⬇️ Download
            </button>
          </div>
        </div>
      </div>

      <!-- Excel Preview -->
      <div v-if="showPreview && selectedFileData" class="preview-container">
        <div class="preview-header">
          <h4>Vorschau: {{ selectedFileData.filename }}</h4>
          <button @click="closePreview" class="close-btn">×</button>
        </div>
        <ViewExcel :data="selectedFileData.data" />
      </div>
    </div>

    <!-- Processing Status -->
    <div v-if="isProcessing" class="processing-status">
      <div class="spinner"></div>
      <p>{{ processingMessage }}</p>
    </div>
  </div>
</template>

<script>
import UpDownButton from '../isybauxml/UpDownButton.vue'
import XmlFix from '../isybauxml/XmlFix.vue'
import ViewExcel from '../isybauxml/ViewExcel.vue'
import axios from 'axios'

export default {
  name: 'Isybauxml',
  
  components: {
    UpDownButton,
    XmlFix,
    ViewExcel
  },

  data() {
    return {
      isProcessing: false,
      isGenerating: false,
      processingMessage: '',
      statusMessage: '',
      statusType: 'success',
      showGenerateButton: false,
      currentFileName: null,
      showXmlFix: false,
      xmlError: null,
      xmlContent: null,
      hasError: false,
      generatedFiles: [],
      selectedFileData: null,
      showPreview: false
    }
  },

  methods: {
    async handleFileUpload(fileData) {
      this.isProcessing = true
      this.processingMessage = 'Überprüfe Datei...'
      this.statusMessage = ''
      this.showGenerateButton = false
      this.xmlError = null
      this.hasError = false
      
      try {
        const file = fileData.file

        // Check if file is XML
        if (!file.name.toLowerCase().endsWith('.xml')) {
          throw new Error('Bitte nur XML-Dateien hochladen')
        }

        // Quick content check
        const firstBytes = await file.slice(0, 100).text()
        if (!firstBytes.includes('<?xml')) {
          throw new Error('Die Datei scheint keine gültige XML-Datei zu sein')
        }

        const formData = new FormData()
        formData.append('file', file)
        
        // Read file content for preview
        const reader = new FileReader()
        reader.onload = (e) => {
          this.xmlContent = e.target.result
        }
        reader.readAsText(file)
        
        // Upload file
        const response = await axios.post('http://localhost:8001/FastAPI/isy/load_xml', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          },
          withCredentials: false
        })

        this.currentFileName = response.data.filename
        this.showGenerateButton = true
        this.statusMessage = `Datei erfolgreich hochgeladen als ${response.data.filename}!`
        this.statusType = 'success'

      } catch (error) {
        console.error('Upload error:', error)
        let errorMessage = 'Unbekannter Fehler'
        
        if (error.message) {
          // Custom error messages from our validation
          errorMessage = error.message
        } else if (error.response && error.response.data && error.response.data.detail) {
          // Backend error messages
          errorMessage = error.response.data.detail
        } else if (error.code === 'ERR_NETWORK') {
          // Network/CORS errors
          errorMessage = 'Verbindungsfehler zum Server. Bitte überprüfen Sie Ihre Verbindung.'
        }
        
        this.statusMessage = `Fehler beim Hochladen: ${errorMessage}`
        this.statusType = 'error'
        this.showGenerateButton = false
      } finally {
        this.isProcessing = false
      }
    },

    async handleGenerateExcel() {
      this.isGenerating = true
      this.statusMessage = 'Generiere Excel Dateien...'
      this.statusType = 'info'
      this.generatedFiles = [] // Reset files list

      try {
        const response = await axios.post('http://localhost:8001/FastAPI/isy/get_mass')
        this.generatedFiles = response.data.files
        this.statusMessage = `${this.generatedFiles.length} Excel Dateien wurden generiert!`
        this.statusType = 'success'
      } catch (error) {
        console.error('Generate error:', error)
        if (error.response && error.response.data && error.response.data.detail) {
          const errorDetail = error.response.data.detail
          
          if (errorDetail.line && errorDetail.column) {
            this.xmlError = {
              detail: `${errorDetail.message}: ${errorDetail.error}`,
              line: errorDetail.line,
              column: errorDetail.column,
              type: errorDetail.type
            }
            this.hasError = true
            this.statusMessage = `XML Validierungsfehler gefunden:
              ${errorDetail.type === 'mismatched_tag' ? 'Nicht übereinstimmende XML Tags' : errorDetail.error}
              (Zeile ${errorDetail.line}, Spalte ${errorDetail.column})`
            this.statusType = 'error'
          } else {
            this.statusMessage = errorDetail.message || 'Fehler beim Generieren der Excel Datei'
            this.statusType = 'error'
          }
        } else {
          this.statusMessage = 'Unerwarteter Fehler beim Generieren der Excel Datei'
          this.statusType = 'error'
        }
      } finally {
        this.isGenerating = false
      }
    },

    async downloadFile(filename) {
      try {
        this.statusMessage = `Lade ${filename} herunter...`
        this.statusType = 'info'
        
        const response = await axios.get(
          `http://localhost:8001/FastAPI/isy/download/${filename}`,
          { 
            responseType: 'blob',
            timeout: 30000  // 30 second timeout
          }
        )
        
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
        
        this.statusMessage = `${filename} erfolgreich heruntergeladen`
        this.statusType = 'success'
      } catch (error) {
        console.error('Download error:', error)
        this.statusMessage = `Fehler beim Herunterladen von ${filename}: ${
          error.response && error.response.data && error.response.data.detail 
            ? error.response.data.detail 
            : 'Unbekannter Fehler'
        }`
        this.statusType = 'error'
      }
    },

    async handleDownload() {
      // To be implemented later
      console.log('Download requested')
    },

    clearError() {
      this.xmlError = null
      this.hasError = false
    },

    async handleXmlSave(editedContent) {
      this.isProcessing = true
      this.statusMessage = 'Speichere XML Änderungen...'
      this.statusType = 'info'

      try {
        // Create a new Blob with the edited content
        const blob = new Blob([editedContent], { type: 'text/xml' })
        const file = new File([blob], this.currentFileName, { type: 'text/xml' })
        
        const formData = new FormData()
        formData.append('file', file)

        // Upload the edited file
        await axios.post('http://localhost:8001/FastAPI/isy/load_xml', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        // Update local content
        this.xmlContent = editedContent
        this.xmlError = null
        this.hasError = false
        this.statusMessage = 'XML erfolgreich aktualisiert'
        this.statusType = 'success'
        this.showGenerateButton = true

      } catch (error) {
        console.error('Save error:', error)
        this.statusMessage = 'Fehler beim Speichern der Änderungen'
        this.statusType = 'error'
      } finally {
        this.isProcessing = false
      }
    },

    async previewFile(file) {
      try {
        this.statusMessage = `Lade Vorschau für ${file.filename}...`
        this.statusType = 'info'
        
        const response = await axios.get(
          `http://localhost:8001/FastAPI/isy/preview/${file.filename}`
        )
        
        this.selectedFileData = {
          filename: file.filename,
          data: response.data
        }
        this.showPreview = true
        this.statusMessage = ''
      } catch (error) {
        console.error('Preview error:', error)
        this.statusMessage = `Fehler beim Laden der Vorschau: ${
          error.response && error.response.data && error.response.data.detail 
            ? error.response.data.detail 
            : 'Unbekannter Fehler'
        }`
        this.statusType = 'error'
      }
    },

    closePreview() {
      this.showPreview = false
      this.selectedFileData = null
    },

    getFileTypeLabel(type) {
      const types = {
        'haltungen_csv': 'Haltungen (CSV)',
        'schacht_csv': 'Schächte (CSV)',
        'excel_complete': 'Komplette Berechnung (Excel)'
      }
      return types[type] || type
    }
  }
}
</script>

<style scoped>
.isybau-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header h1 {
  font-size: 2.5rem;
  color: #1a365d;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #4a5568;
  font-size: 1.1rem;
}

.status-message {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  font-family: monospace;
}

.processing-status {
  text-align: center;
  padding: 2rem;
  margin-top: 1rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2196F3;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.generate-section {
  margin: 2rem 0;
  text-align: center;
}

.generate-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.2s;
}

.generate-btn:hover:not(:disabled) {
  background: #388E3C;
}

.generate-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.status-message.info {
  background-color: #e3f2fd;
  color: #0d47a1;
  border: 1px solid #bbdefb;
}

.xml-preview-section {
  margin: 2rem 0;
}

.excel-preview-section {
  margin: 2rem 0;
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}

.file-info {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.file-type {
  font-weight: bold;
  color: #2196F3;
  min-width: 150px;
}

.file-name {
  color: #4a5568;
}

.file-actions {
  display: flex;
  gap: 0.5rem;
}

.preview-btn, .download-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.preview-btn {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
}

.download-btn {
  background: #4CAF50;
  color: white;
}

.preview-container {
  margin-top: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
}

.file-description {
  color: #666;
  font-size: 0.9em;
  margin-left: 1rem;
}
</style>
