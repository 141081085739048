<template>
  <div class="home">
    <div class="welcome-section">
      <h1>Willkommen bei QUAGG</h1>
      <p>Inspektion & Infrastruktur</p>
    </div>

    <div class="tools-grid">
      <!-- Existing tools -->
      <div class="tool-card">
        <h3>Inspektion</h3>
        <p>Verwalten Sie Ihre Inspektionsdaten und PDF-Dokumente.</p>
        <router-link to="/inspection" class="tool-link">
          Zur Inspektion
        </router-link>
      </div>

      <div class="tool-card">
        <h3>ISYBAU XML</h3>
        <p>Konvertieren und analysieren Sie ISYBAU XML-Dateien.</p>
        <router-link to="/isybauxml" class="tool-link">
          Zum XML-Tool
        </router-link>
      </div>

      <!-- New Newton Calculator tool card -->
      <div class="tool-card">
        <h3>Aushub Berechnung</h3>
        <p>Berechnen Sie Flächen und Volumina von unregelmäßigen Körpern.</p>
        <router-link to="/aushub" class="tool-link">
          Zum Rechner
        </router-link>
      </div>
    </div>

    <div class="tech-stack">
      <h2>Tech Stack</h2>
      <div class="tech-grid">
        <div class="tech-item">
          <h3>Frontend</h3>
          <ul>
            <li>Vue.js</li>
            <li>JavaScript</li>
            <li>HTML5 & CSS3</li>
            <li>. . .</li>
          </ul>
        </div>
        <div class="tech-item">
          <h3>Backend</h3>
          <ul>
            <li>Python</li>
            <li>FastAPI</li>
            <li>SQLite</li>
            <li>. . .</li>
          </ul>
        </div>
        <div class="tech-item">
          <h3>Tools & Deployment</h3>
          <ul>
            <li>PM2</li>
            <li>Cursor</li>
            <li>Nginx</li>
            <li>. . .</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.welcome-section {
  text-align: center;
  margin-bottom: 3rem;
}

.welcome-section h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.welcome-section p {
  font-size: 1.2rem;
  color: #666;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.tool-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.tool-card h3 {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.tool-card p {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.tool-link {
  display: inline-block;
  background: #42b983;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  transition: background 0.2s;
}

.tool-link:hover {
  background: #3aa876;
}

.tech-stack {
  margin-top: 4rem;
  padding: 2rem;
  background: var(--paper-color);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(44, 62, 80, 0.1);
}

.tech-stack h2 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.tech-item {
  text-align: center;
}

.tech-item h3 {
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.tech-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tech-item li {
  color: var(--text-color);
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border-color);
}

.tech-item li:last-child {
  border-bottom: none;
}

.author {
  font-size: 0.9rem;
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .home {
    padding: 1rem;
  }
  
  .welcome-section h1 {
    font-size: 2rem;
  }
  
  .tools-grid {
    grid-template-columns: 1fr;
  }

  .tech-grid {
    grid-template-columns: 1fr;
  }
}
</style>