import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Secure from '../views/Secure.vue'
import Inspection from '../views/Inspection.vue'
import Isybauxml from '../views/Isybauxml.vue'
import Aushub from '../views/Aushub.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/secure',
    name: 'Secure',
    component: Secure
  },
  {
    path: '/inspection',
    name: 'Inspection',
    component: Inspection
  },
  {
    path: '/isybauxml',
    name: 'Isybauxml',
    component: Isybauxml
  },
  {
    path: '/aushub',
    name: 'Aushub',
    component: Aushub
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
