<template>
  <div class="upload-download-container">
    <div class="upload-section">
      <div class="button-group">
        <button 
          class="action-btn upload-btn" 
          @click="handleUpload"
          :disabled="isUploading"
        >
          <span class="icon">📤</span>
          Upload XML
        </button>
      </div>
      <input
        type="file"
        ref="fileInput"
        @change="onFileSelected"
        accept=".xml"
        style="display: none"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpDownButton',
  
  data() {
    return {
      isUploading: false
    }
  },

  methods: {
    handleUpload() {
      this.$refs.fileInput.click()
    },

    async onFileSelected(event) {
      const file = event.target.files[0]
      if (!file) return

      this.isUploading = true
      try {
        this.$emit('file-selected', { file })
      } catch (error) {
        console.error('File selection error:', error)
      } finally {
        this.isUploading = false
        this.$refs.fileInput.value = ''
      }
    }
  }
}
</script>

<style scoped>
.upload-download-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
  width: 100%;
  margin-top: 2rem;
}

.upload-section {
  background: var(--paper-color);
  padding: 1.5rem;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(44, 62, 80, 0.1);
  text-align: center;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  background: var(--primary-color);
  color: var(--paper-color);
}

.action-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.upload-btn {
  background: var(--primary-color);
  color: var(--paper-color);
}

.upload-btn:hover:not(:disabled) {
  background: var(--hover-color);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(44, 62, 80, 0.2);
}

.icon {
  font-size: 1.2rem;
}
</style>
