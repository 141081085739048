<template>
  <div class="excel-viewer">
    <div class="toolbar">
      <div class="search-bar">
        <input 
          type="text" 
          v-model="searchQuery" 
          placeholder="Suchen..."
          @input="filterData"
        >
      </div>
      <div class="view-controls">
        <button @click="toggleView">
          {{ isTableView ? '📊 Grafik' : '📋 Tabelle' }}
        </button>
      </div>
    </div>

    <div class="content-area">
      <div v-if="isTableView" class="table-view">
        <table v-if="filteredData.length">
          <thead>
            <tr>
              <th v-for="header in headers" :key="header">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in filteredData" :key="index">
              <td v-for="header in headers" :key="header">{{ row[header] }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="no-data">
          Keine Daten verfügbar
        </div>
      </div>
      <div v-else class="chart-view">
        <div class="chart-controls">
          <select v-model="selectedColumn" v-if="headers.length">
            <option value="">Spalte wählen...</option>
            <option v-for="header in headers" :key="header" :value="header">
              {{ header }}
            </option>
          </select>
        </div>
        <bar-chart v-if="chartData" :chart-data="chartData" :options="chartOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'ViewExcel',
  
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  components: {
    'bar-chart': {
      extends: Bar,
      props: ['chartData', 'options'],
      mounted() {
        this.renderChart(this.chartData, this.options)
      },
      watch: {
        chartData() {
          this.renderChart(this.chartData, this.options)
        }
      }
    }
  },

  data() {
    return {
      searchQuery: '',
      isTableView: true,
      filteredData: [],
      headers: [],
      selectedColumn: '',
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },

  created() {
    this.initializeData()
  },

  methods: {
    initializeData() {
      if (this.data.length) {
        this.headers = Object.keys(this.data[0])
        this.filteredData = [...this.data]
      }
    },

    filterData() {
      if (!this.searchQuery) {
        this.filteredData = [...this.data]
        return
      }

      const query = this.searchQuery.toLowerCase()
      this.filteredData = this.data.filter(row => 
        Object.values(row).some(value => 
          String(value).toLowerCase().includes(query)
        )
      )
    },

    toggleView() {
      this.isTableView = !this.isTableView
    },

    processDataForChart() {
      if (!this.selectedColumn) return

      const valueMap = new Map()
      this.filteredData.forEach(row => {
        const value = row[this.selectedColumn]
        valueMap.set(value, (valueMap.get(value) || 0) + 1)
      })

      this.chartData = {
        labels: Array.from(valueMap.keys()),
        datasets: [{
          label: this.selectedColumn,
          backgroundColor: '#2196F3',
          data: Array.from(valueMap.values())
        }]
      }
    }
  },

  watch: {
    data: {
      handler: 'initializeData',
      immediate: true
    },
    selectedColumn() {
      this.processDataForChart()
    },
    isTableView(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.processDataForChart()
        })
      }
    }
  }
}
</script>

<style scoped>
.excel-viewer {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 1rem;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.search-bar input {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  width: 200px;
}

.view-controls button {
  padding: 0.5rem 1rem;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
}

.content-area {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

th {
  background: #f8fafc;
  font-weight: 600;
}

tr:hover {
  background: #f8fafc;
}

.no-data {
  text-align: center;
  padding: 2rem;
  color: #64748b;
}

.chart-view {
  padding: 1rem;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chart-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.chart-controls select {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: white;
}

.chart-container {
  flex: 1;
  min-height: 300px;
}
</style>
