<template>
  <div class="aushub-container">
    <div class="header">
      <h1>Aushub Berechnung</h1>
      <p class="subtitle">Berechnung von unregelmäßigen Volumina</p>
    </div>

    <div class="calculator-section">
      <excalc />
    </div>
  </div>
</template>

<script>
import excalc from '../excavation/excalc.vue'

export default {
  name: 'Aushub',
  components: {
    excalc
  }
}
</script>

<style scoped>
.aushub-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.subtitle {
  color: #666;
  font-size: 1.1rem;
}

.calculator-section {
  background: white;
  border-radius: 12px;
  padding: 4rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
</style>
