<template>
  <div class="login-container">
    <div class="login-card">
      <div class="login-header">
        <h1>Login</h1>
        <p class="subtitle">Zugang Intern</p>
      </div>
      
      <form @submit.prevent="login" class="login-form">
        <div class="form-group">
          <div class="input-container">
            <input 
              id="username"
              type="text" 
              v-model="username" 
              placeholder=" "
              required
            >
            <label for="username">Benutzername</label>
          </div>
        </div>
        
        <div class="form-group">
          <div class="input-container">
            <input 
              id="password"
              type="password" 
              v-model="password" 
              placeholder=" "
              required
            >
            <label for="password">Passwort</label>
          </div>
        </div>

        <button type="submit" class="login-button">
          Anmelden
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('http://localhost:5000/login', {
          username: this.username,
          password: this.password
        })
        if (response.data.success) {
          this.$router.push('/secure')
        }
      } catch (error) {
        console.error('Login failed:', error)
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  padding: 2rem;
}

.login-card {
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 400px;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-header h1 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  font-size: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  position: relative;
}

.input-container {
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  background: white;
  color: #2c3e50;
}

.form-group label {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  transition: all 0.2s;
  pointer-events: none;
  background: white;
  padding: 0 0.25rem;
}

.form-group input:focus,
.form-group input:not(:placeholder-shown) {
  border-color: #42b983;
  outline: none;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label {
  transform: translateY(-170%);
  font-size: 0.85rem;
  color: #42b983;
}

.login-button {
  background: #42b983;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  margin-top: 0.5rem;
}

.login-button:hover {
  background: #3aa876;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(66, 185, 131, 0.2);
}

/* Error state styles */
.form-group input.error {
  border-color: #dc3545;
}

.form-group .error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

@media (max-width: 480px) {
  .login-card {
    padding: 1.5rem;
  }

  .login-header h1 {
    font-size: 1.75rem;
  }
}
</style>