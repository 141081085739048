<template>
  <div class="xml-fix-container">
    <!-- Error Summary Panel -->
    <div v-if="hasError" class="error-panel">
      <div class="error-summary">
        <h3>XML Fehler</h3>
        <div class="error-stats">
          <span class="error-count">Fehler gefunden: {{ errorCount }}</span>
          <span class="current-error">Aktueller Fehler: {{ currentErrorIndex + 1 }}/{{ errorCount }}</span>
        </div>
      </div>
      
      <div class="error-details">
        <div class="error-location">
          <span>Zeile: {{ errorLine }}</span>
          <span>Spalte: {{ errorColumn }}</span>
        </div>
        <p class="error-message">{{ errorMessage }}</p>
      </div>

      <!-- Error Navigation -->
      <div class="error-navigation">
        <button @click="previousError" :disabled="currentErrorIndex <= 0" class="nav-btn">
          <span>←</span> Vorheriger Fehler
        </button>
        <button @click="nextError" :disabled="currentErrorIndex >= errorCount - 1" class="nav-btn">
          Nächster Fehler <span>→</span>
        </button>
      </div>

      <div v-if="errors[currentErrorIndex] && errors[currentErrorIndex].context" class="error-context">
        <pre v-for="(line, i) in errors[currentErrorIndex].context.before" 
             :key="'before-'+i" 
             class="context-line">{{ line }}</pre>
        <pre class="error-line">{{ errors[currentErrorIndex].context.error }}</pre>
        <pre v-for="(line, i) in errors[currentErrorIndex].context.after" 
             :key="'after-'+i" 
             class="context-line">{{ line }}</pre>
      </div>
    </div>

    <!-- Editor Controls -->
    <div class="editor-controls">
      <button @click="toggleEditMode" class="control-btn">
        {{ isEditing ? 'Ansichtsmodus' : 'Bearbeitungsmodus' }}
      </button>
      <button @click="autoFix" class="control-btn auto-fix" :disabled="!hasError">
        Auto-Fix versuchen
      </button>
    </div>

    <!-- XML Editor -->
    <div class="xml-editor">
      <div class="line-numbers">
        <div v-for="n in totalLines" 
             :key="n" 
             :class="{ 
               'error-line': isErrorLine(n),
               'current-error': isCurrentErrorLine(n)
             }"
             class="line-number"
        >
          {{ n }}
        </div>
      </div>
      <div class="editor-content" ref="editorContent">
        <textarea
          v-if="isEditing"
          v-model="editedContent"
          class="xml-content editable"
          spellcheck="false"
          @scroll="syncScroll"
          ref="editor"
        ></textarea>
        <pre 
          v-else
          class="xml-content" 
          :class="{ 'error-content': hasError }"
          ref="xmlContent"
        ><code v-for="(line, index) in xmlLines" 
              :key="index"
              :class="{ 
                'error-line': isErrorLine(index + 1),
                'current-error': isCurrentErrorLine(index + 1)
              }"
        >{{ line }}</code></pre>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="actions" v-if="isEditing">
      <button class="save-btn" @click="saveChanges" :disabled="!hasChanges">
        Änderungen speichern
      </button>
      <button class="cancel-btn" @click="cancelEdit">
        Abbrechen
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XmlFix',

  props: {
    xmlContent: {
      type: String,
      required: true
    },
    errorDetails: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editedContent: '',
      hasChanges: false,
      xmlLines: [],
      hasError: false,
      isEditing: false,
      errors: [], // Array of all errors found
      currentErrorIndex: 0,
      errorLine: 0,
      errorColumn: 0,
      errorMessage: ''
    }
  },

  computed: {
    totalLines() {
      return this.xmlLines.length
    },
    errorCount() {
      return this.errors.length
    }
  },

  created() {
    this.parseError(this.errorDetails)
    this.processXmlContent()
  },

  methods: {
    processXmlContent() {
      if (this.xmlContent) {
        this.xmlLines = this.xmlContent.split('\n')
        this.editedContent = this.xmlContent
      }
    },

    parseError(error) {
      if (error && error.detail) {
        this.hasError = true
        this.errorLine = error.line
        this.errorColumn = error.column
        this.errorMessage = `${error.message}: ${error.error}`
        
        // Add the error to our errors array
        this.errors = [{
          line: error.line,
          column: error.column,
          message: error.error,
          type: error.type,
          lineContent: error.lineContent,
          context: error.context
        }]
        
        // Scroll to error after a short delay to ensure content is loaded
        this.$nextTick(() => {
          this.scrollToError()
        })
      }
    },

    isErrorLine(lineNumber) {
      return this.hasError && lineNumber === this.errorLine
    },

    scrollToError() {
      if (this.errorLine) {
        const xmlContent = this.$refs.xmlContent
        if (xmlContent && xmlContent.children) {
          const errorElement = xmlContent.children[this.errorLine - 1]
          if (errorElement) {
            errorElement.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center' 
            })
            
            // Highlight the specific column
            if (this.errorColumn && errorElement.firstChild) {
              const range = document.createRange()
              const textNode = errorElement.firstChild
              range.setStart(textNode, this.errorColumn - 1)
              range.setEnd(textNode, this.errorColumn)
              
              // Clear any existing selection
              window.getSelection().removeAllRanges()
              window.getSelection().addRange(range)
            }
          }
        }
      }
    },

    scrollToTop() {
      this.$refs.editorContent.scrollTop = 0
    },

    saveChanges() {
      this.$emit('save', this.editedContent)
    },

    syncScroll(e) {
      const lineNumbers = this.$el.querySelector('.line-numbers')
      if (lineNumbers) {
        lineNumbers.scrollTop = e.target.scrollTop
      }
    },

    cancelEdit() {
      this.editedContent = this.xmlContent
      this.hasChanges = false
      this.$emit('cancel')
    },

    toggleEditMode() {
      this.isEditing = !this.isEditing
      if (!this.isEditing) {
        this.cancelEdit()
      }
    },

    isCurrentErrorLine(lineNumber) {
      return this.hasError && 
             this.errors[this.currentErrorIndex] && 
             lineNumber === this.errors[this.currentErrorIndex].line
    },

    previousError() {
      if (this.currentErrorIndex > 0) {
        this.currentErrorIndex--
        this.updateCurrentError()
      }
    },

    nextError() {
      if (this.currentErrorIndex < this.errorCount - 1) {
        this.currentErrorIndex++
        this.updateCurrentError()
      }
    },

    updateCurrentError() {
      const error = this.errors[this.currentErrorIndex]
      if (error) {
        this.errorLine = error.line
        this.errorColumn = error.column
        this.errorMessage = error.message
        this.scrollToError()
      }
    },

    autoFix() {
      // To be implemented - will handle automatic fixing
      console.log('Auto-fix not yet implemented')
    }
  },

  watch: {
    xmlContent: {
      handler() {
        this.processXmlContent()
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.xml-fix-container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
}

.error-info {
  margin-bottom: 1rem;
  padding: 1rem;
  background: #fff3f3;
  border-radius: 4px;
}

.error-message {
  color: #dc3545;
  margin: 0.5rem 0;
  font-family: monospace;
}

.error-location {
  display: flex;
  gap: 1rem;
  font-family: monospace;
  background: #f8f9fa;
  padding: 0.5rem;
  border-radius: 4px;
}

.xml-editor {
  display: flex;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  margin: 1rem 0;
  height: 500px;
  overflow: hidden;
}

.line-numbers {
  background: #f8fafc;
  padding: 0.5rem;
  border-right: 1px solid #e2e8f0;
  text-align: right;
  overflow-y: hidden;
  user-select: none;
  min-width: 3rem;
}

.line-number {
  font-family: monospace;
  color: #718096;
  padding: 0 0.5rem;
}

.editor-content {
  flex: 1;
  overflow: auto;
  background: #ffffff;
}

.xml-content {
  margin: 0;
  padding: 0.5rem;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre;
}

code {
  display: block;
  padding: 0 0.5rem;
}

.error-line {
  background: #ffebee;
  color: #dc3545;
  font-weight: bold;
  position: relative;
}

.error-column {
  background: #ffd700;
  position: absolute;
  height: 100%;
  width: 2px;
}

.error-context {
  padding: 0.5rem;
  background: #fff3f3;
  border-left: 3px solid #dc3545;
  margin: 0.5rem 0;
  font-family: monospace;
}

.error-context pre {
  margin: 0;
  white-space: pre-wrap;
}

.error-context .context-line {
  opacity: 0.7;
}

.error-context .error-line {
  font-weight: bold;
  color: #dc3545;
}

.navigation-controls {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  justify-content: center;
}

.nav-btn {
  padding: 0.5rem 1rem;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.nav-btn:hover {
  background: #edf2f7;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.save-btn, .cancel-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.save-btn {
  background: #4CAF50;
  color: white;
}

.save-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cancel-btn {
  background: #dc3545;
  color: white;
}

.xml-content.editable {
  width: 100%;
  height: 100%;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 0.5rem;
  border: none;
  resize: none;
  outline: none;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: auto;
}

.xml-content.editable:focus {
  background-color: #fff;
}

.error-panel {
  background: #fff3f3;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.error-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.error-stats {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
}

.error-count {
  color: #dc3545;
  font-weight: bold;
}

.current-error {
  color: #0d47a1;
}

.error-navigation {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.editor-controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.control-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: #f8fafc;
  cursor: pointer;
  transition: all 0.2s;
}

.control-btn:hover:not(:disabled) {
  background: #edf2f7;
}

.auto-fix {
  background: #4CAF50;
  color: white;
  border: none;
}

.auto-fix:hover:not(:disabled) {
  background: #388E3C;
}

.current-error {
  background: #ffd700 !important;
  font-weight: bold;
}
</style>
